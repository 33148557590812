body,
html {
  margin: 0;
  width: '1144px';
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: local('Inter'), url(./fonts/Inter-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: local('Inter'), url(./fonts/Inter-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter'), url(./fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: local('Inter'), url(./fonts/Inter-Black.ttf) format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
